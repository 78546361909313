import { INTL_ROUTE_TO_LOCALE_MAP } from '@/i18n';

const { DEFAULT_FLOW } = process.env;

function loadLang(route) {
  const { lang, flowName, stepName } = route.params;

  let langRedirect = null;

  const isValidLang = lang === '' || Object.keys(INTL_ROUTE_TO_LOCALE_MAP).includes(lang);

  if (!isValidLang) {
    langRedirect = {
      name: 'step',
      params: {
        lang: '',
        flowName: flowName || DEFAULT_FLOW,
        stepName: stepName || '',
      },
      query: route.query,
      replace: true,
    };
  }

  return { langRedirect };
}


// Exports
export default function useLang() {
  return {
    loadLang,
  };
}
