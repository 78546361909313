import axios from 'axios';

// Config
// axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
// axios.defaults.withCredentials = process.env.NODE_ENV !== 'development';

// Request Interceptor
axios.interceptors.request.use((request) => {
  const accessToken = localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_NAME);

  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
  }

  return request;
}, (error) => {
  console.error('Axios request interceptor error: ', error);

  return Promise.reject(error);
});

// Response Interceptor
// axios.interceptors.response.use((response) => response);

export default axios;
