<template>
  <div
    class="TheToaster">
    <TransitionGroup name="toast" tag="div">
      <div
        v-for="toast in toasts"
        class="Toast"
        :class="[`Toast-${toast.theme}`]"
        :key="toast.id"
        v-html="toast.message"
        @click="eatToast(toast.id)">
      </div>
    </TransitionGroup>
  </div>
</template>


<script>
import useToast from '@/use/toast';

export default {
  name: 'TheToaster',

  setup() {
    const {
      toasts,
      eatToast,
      makeToast,
    } = useToast();

    return {
      toasts,
      eatToast,
      makeToast,
    };
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.TheToaster {
  max-width: 400px;
  padding-right: 16px;
  position: fixed;
  right: 0;
  top: 0;
  width: 90%;
  z-index: 999;
}

.Toast {
  background-color: white;
  border-radius: $radius;
  box-shadow: 0 8px 16px -2px rgba($black, 0.16);
  cursor: pointer;
  font-size: 14px;
  margin-top: 32px;
  padding: 16px;
  width: 100%;
  & + .Toast {
    margin-top: 8px;
  }
}

// Themes
.Toast-error {
  background-color: rgba($red, 1);
  border: 2px solid $red;
  color: $white;
}

// Transition
.toast-enter-active,
.toast-leave-active {
  transition: all 0.4s ease;
}
.toast-enter-from,
.toast-leave-to {
  opacity: 0;
  transform: translateX(50%);
}
</style>
