import { isEmpty } from 'lodash';

const loadScript = ({
  src,
  async = true,
  type = 'text/javascript',
}) => new Promise((resolve, reject) => {
  try {
    const scriptEl = document.createElement('script');
    scriptEl.type = type;
    scriptEl.async = async;
    scriptEl.src = src;

    scriptEl.addEventListener('load', () => resolve());
    scriptEl.addEventListener('error', () => reject(new Error(`Failed to load script: ${src}`)));

    document.body.appendChild(scriptEl);
  } catch (error) {
    reject(error);
  }
});

function hasValue(value) {
  const valueType = typeof value;

  let isValue = false;

  if (valueType === 'object') {
    isValue = !isEmpty(value);
  }
  if (valueType === 'boolean') {
    isValue = true;
  }
  if (valueType === 'string') {
    isValue = !!value;
  }

  return isValue;
}

function redirect(redirectUrl) {
  window.location = redirectUrl;
}

function awaitTimeout(delay) {
  return new Promise((resolve) => setTimeout(resolve, delay));
}


// Exports
export default function useUtils() {
  return {
    loadScript,
    hasValue,
    redirect,
    awaitTimeout,
  };
}
