<template>
  <div
    class="__content">
    <!-- Current Step -->
    <template
      v-if="currentStep">
      <component
        :is="computedLayout"
        v-bind="flow?.layout?.props">
        <router-view/>
      </component>
    </template>

    <!-- Loader -->
    <template
      v-else>
      <LoaderBase
        class="__loader"
      />
    </template>
  </div>

  <!-- Toaster -->
  <TheToaster/>

  <!-- Footer -->
  <FooterGlobal
    :socials="socials"
    :privacies="$i18n.messages[$i18n.locale].privacies"
  />
</template>


<script>
import { computed, defineAsyncComponent } from 'vue';
import SOCIALS from '@/data/socials';
import useDomo from '@/use/domo';
import useDevice from '@/use/device';
import useFlow from '@/use/flow';
import useSession from '@/use/session';
import useAnalytics from '@/use/analytics';
import FooterGlobal from '@/components/FooterGlobal.vue';
import TheToaster from '@/components/TheToaster.vue';
import LoaderBase from '@/components/LoaderBase.vue';

export default {
  name: 'App',

  components: {
    FooterGlobal,
    LoaderBase,
    TheToaster,
  },

  setup() {
    const {
      domo,
    } = useDomo();

    const {
      deviceWidth,
      deviceHeight,
    } = useDevice();

    const {
      flow,
      currentStep,
      nextStep,
      stepperSteps,
      isFlowLoaded,
      isStepValidClientSide,
    } = useFlow();

    const {
      session,
      saveSession,
      isSessionLoaded,
    } = useSession();

    const {
      trackEvent,
      amplitude,
    } = useAnalytics();

    return {
      domo,
      deviceWidth,
      deviceHeight,
      flow,
      currentStep,
      nextStep,
      stepperSteps,
      isFlowLoaded,
      isStepValidClientSide,
      session,
      saveSession,
      isSessionLoaded,
      trackEvent,
      amplitude,
    };
  },

  computed: {
    computedLayout() {
      if (!this.flow || !this.currentStep) return null;
      return defineAsyncComponent(() => import(`@/${this.flow?.layout?.path || 'layouts/DefaultLayout'}.vue`));
    },

    isCurrentStepValidClientSide() {
      if (!this.isSessionLoaded || !this.currentStep) return false;

      return this.isStepValidClientSide(
        this.currentStep.routeName,
        this.session,
      );
    },
  },

  provide() {
    return {
      domo: this.domo,
      deviceWidth: computed(() => this.deviceWidth),
      deviceHeight: computed(() => this.deviceHeight),
      flow: computed(() => this.flow),
      currentStep: computed(() => this.currentStep),
      nextStep: computed(() => this.nextStep),
      stepperSteps: computed(() => this.stepperSteps),
      isCurrentStepValidClientSide: computed(() => this.isCurrentStepValidClientSide),
      session: this.session,
      saveSession: this.saveSession,
      trackEvent: this.trackEvent,
      amplitude: this.amplitude,
    };
  },

  data() {
    return {
      socials: SOCIALS,
    };
  },
};
</script>


<style src="@/styles/index.scss" lang="scss"></style>
<style lang="scss" scoped>
@import '@/styles/_variables';

.__content {
  min-height: 100vh;
}

.__loader {
  @include absolute;
}
</style>
