/*
 * Docs for this file here:
 * https://next.router.vuejs.org/guide/advanced/scroll-behavior.html#scroll-behavior
 */

const scrollBehavior = () => ({
  top: 0,
});

export default scrollBehavior;
