<template>
  <div
    class="LoaderBase"
    :class="{ isCheckmark }"
    :style="computedStyle">
    <span
      class="__spinner">
    </span>
    <span
      class="__checkmark">
    </span>
  </div>
</template>


<script>
export default {
  name: 'LoaderBase',

  props: {
    isCheckmark: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 50,
    },
  },

  computed: {
    computedStyle() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
      };
    },
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables';

.LoaderBase {
  position: relative;
  width: 50px;
  height: 50px;
  margin: 0 auto;

  // Spinner
  .__spinner,
  .__spinner:before,
  .__spinner:after,
  .__checkmark {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.2s ease 0s;
  }

  // Spinner circle & top
  .__spinner:before,
  .__spinner:after {
    content: '';
    border-radius: 50%;
    border: 3px solid transparent;
  }

  // Spinner circle
  .__spinner:before {
    opacity: 0.2;
    border-color: currentColor;
  }

  // Spinner Top
  .__spinner:after {
    border-top-color: currentColor;
    animation: spin .7s cubic-bezier(0.560, 0.110, 0.220, 0.865) 0s infinite;
  }


  // Checkmark
  .__checkmark {
    transform: rotate(45deg);
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 25%;
      bottom: 20%;
      width: 40%;
      height: 70%;
      border-right: 3px solid currentColor;
      border-bottom: 3px solid currentColor;
      opacity: 0;
      transform: scale(.5);
      transition: all 0.2s ease 0s;
    }
  }


  // isCheckmark
  &.isCheckmark {
    .__spinner {
      opacity: 0;
      transform: scale(0.5);
    }
    .__checkmark:after {
      opacity: 1;
      transform: scale(1);
      animation: check 0.4s ease 0s forwards;
    }
  }


  // Animation keyframes
  @keyframes spin {
    from  { transform: rotate(0deg); }
    to    { transform: rotate(360deg); }
  }
  @keyframes check {
    0%    { width: 0%; height: 0%; }
    50%   { width: 40%; height: 0%; }
    100%  { height: 70%; }
  }
}
</style>
