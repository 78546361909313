import {
  ref,
} from 'vue';
import useUtils from '@/use/utils';

const { loadScript } = useUtils();


// Properties
const isUtmLoaded = ref(false);


// Load Utm
async function loadUtm() {
  if (isUtmLoaded.value) return;

  try {
    await loadScript({
      src: '/dist/assets/js/utm.js',
    });
  } catch (error) {
    console.error('FAILED TO LOAD UTM: ', error);
  }

  isUtmLoaded.value = true;
}


// Exports
export default function useUtm() {
  return {
    loadUtm,
  };
}
