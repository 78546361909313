import useFlow from '@/use/flow';

const {
  setCurrentStepId,
} = useFlow();

export default function afterEach(to) {
  const {
    stepName,
  } = to.params;

  setCurrentStepId(stepName);
}
