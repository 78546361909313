export default {
  // CTAS
  ctas: {
    alreadyDomoUser: 'Sie sind bereits Domo-Benutzer?',
    createAccount: 'KONTO ERSTELLEN',
    launchDomoNow: 'Launch Domo Now',
    next: 'Weiter',
    finishAndLaunchDomo: 'Abschließen und Domo starten',
    signIn: 'Anmelden',
    signUp: 'Registrieren',
  },

  // SHARED
  shared: {
    businessEmail: 'Geschäftliche E-Mail',
    businessEmailError: 'Bitte geben Sie eine gültige geschäftliche E-Mail-Adresse ein',
    changeEmail: 'E-Mail-Adresse ändern.',
    checkSpam: 'Prüfen Sie Ihren Spam-Ordner.',
    clickToResend: 'click to resend',
    company: 'Unternehmen',
    companyNameIsRequired: '* Company name is required',
    confirmationCode: 'Bestätigungscode',
    confirmationCodeError: 'Dieser Code stimmt nicht mit dem Code überein, den wir an {email} gesendet haben. Versuchen Sie es erneut.',
    contactForAssistance: "Please contact <a class='color-link-blue link' href='mailto:domofree{'@'}domo.com' target='_blank'>domofree{'@'}domo.com</a> for assistance.",
    country: 'Land',
    departmentOrTeam: 'Abteilung oder Team',
    firstName: 'Vorname',
    firstNameIsRequired: '* Vorname ist erforderlich',
    jobTitle: 'Stellenbezeichnung',
    jobTitleIsRequired: '* Job title is required',
    lastName: 'Nachname',
    lastNameIsRequired: '* Nachname ist erforderlich',
    launchingDomo: 'Launching Domo',
    launchingInSeconds: 'Launching Domo in {seconds} seconds',
    notReceivedEmail: 'Haben Sie unsere E-Mail nicht erhalten?',
    notSeeingYourRole: 'Sie sehen Ihre Rolle nicht?',
    notSeeingYourTeam: 'Sie sehen Ihr Team nicht?',
    notSeeingYourUseCase: 'Not seeing your use case?',
    password: 'Kennwort',
    passwordLowercase: '1 Kleinbuchstabe',
    passwordLowercaseRequired: 'Mindestens 1 Kleinbuchstabe erforderlich',
    passwordMinimum: 'Mindestens 8 Zeichen',
    passwordMinimumRequired: 'Mindestens 8 Zeichen erforderlich',
    passwordNumber: '1 Ziffer',
    passwordNumberRequired: 'Mindestens 1 Ziffer erforderlich',
    passwordRequirements: "Kennwörter müssen mindestens 8 Zeichen, darunter Groß- und Kleinbuchstaben, eine Zahl und mindestens 1 Sonderzeichen ({'!,@,#,$'}) enthalten.",
    passwordRequirementsLabel: 'Passwords must contain:',
    passwordSpecial: "1 special character (ex: {'@$!#'})",
    passwordSpecialRequired: 'At least 1 special character required',
    passwordUppercase: '1 Großbuchstabe',
    passwordUppercaseRequired: 'Mindestens 1 Großbuchstabe erforderlich',
    phone: 'Telefonnummer',
    pleaseCompleteFields: 'Füllen Sie alle erforderlichen Felder aus',
    pleaseEnterValidPhoneNumber: '* Geben Sie eine gültige Telefonnummer ein',
    primaryObjectiveRequired: '* Primary objective is required',
    rateLimitError: 'Zu viele Anforderungen mit Fehlern. Ihre Rate wurde begrenzt. Versuchen Sie es später erneut.',
    resendsRemaining: '({attempts} attempt remaining) | ({attempts} attempts remaining)',
    resendError: 'Unable to resend code, please try again later',
    resendingCode: 'Resending confirmation code',
    resentCode: 'Confirmation code sent',
    role: 'Rolle',
    roleIsRequired: '* Role is required',
    search: 'Search',
    teamIsRequired: '* Team is required',
    tellUsHowYouWantToUseDomo: 'Tell us how you want to use Domo',
    tellUsWhatYouDo: 'Teilen Sie uns mit, was Sie tun.',
    trustedBy: 'DIESE UNTERNEHMEN VERTRAUEN AUF DOMO:',
    useCase: 'Use case',
    walkthroughIsRequired: 'Walk-through response is required',
    wantToScheduleLater: 'Want to schedule later?',
    whatBestDescribesYourRole: 'Was beschreibt Ihre Rolle im Umgang mit Daten am besten?',
    whatIsYourJobTitle: 'Wie lautet Ihre Stellenbezeichnung?',
    whatTeamAreYouOn: 'In welchem Team sind Sie?',
    whatTeamDoYouWorkOn: 'In welchem Team arbeiten Sie?',
    whatWouldYouLikeToAccomplish: 'What would you like to accomplish with Domo?',
    wrongAddress: 'Ist {email} die falsche Adresse?',
  },

  // LEGAL
  legal: {
    acknowledgePrivacyPolicy: 'Mit der Erstellung eines kostenlosen Kontos erkennen Sie den <a class="__legal-link font-semi-bold nowrap" href="https://www.domo.com/company/privacy-policy" target="_blank">Datenschutzhinweis</a> an.',
    agreeToTerms: 'I agree to the <a class="underline" href="https://www.domo.com/company/service-terms" target="_blank">Domo Software as a Service Agreement</a> and acknowledge Domo\'s <a class="underline" href="https://www.domo.com/company/privacy-policy" target="_blank">Privacy Notice</a>.',
    autoAgreeToTerms: 'Wenn Sie auf „Weiter“ klicken, stimmen Sie unseren <a class="underline" href="https://www.domo.com/company/service-terms" target="_blank">Vertragsbedingungen</a> und unserer <a class="underline" href="https://www.domo.com/company/privacy-policy" target="_blank">Datenschutz- und Cookie-Richtlinie</a> zu.',
    autoConsent: 'Domo kann Ihnen Informationen und Angebote über Produkte und geschäftsbezogene Services von Domo senden.',
    managePreferences: '<a class="underline" href="https://www.domo.com/unsubscribe" target="_blank">Klicken Sie hier</a>, um sich abzumelden oder Ihre Abonnementeinstellungen zu verwalten.',
    patents: 'Patente',
    privacyCookiePolicy: 'Datenschutz- und Cookie-Richtlinie',
    privacyNotice: 'Privacy Notice',
    requestConsent: 'Erlauben Sie Domo, Ihnen Informationen und Angebote über Produkte und geschäftsbezogene Services von Domo zu senden.',
    termsOfService: 'Vertragsbedingungen',
  },

  // PRIVACY LINKS
  privacies: [
    {
      label: 'Cookie Preferences',
      href: 'https://domo.com/company/cookies',
    },
    {
      label: 'Privacy & Cookie Policy',
      href: 'https://domo.com/company/privacy-policy',
    },
    {
      label: 'Patents',
      href: 'https://domo.com/company/patents',
    },
  ],

  // FLOWS
  flows: {

    // SHARED
    shared: {
      steps: {
        email: {
          title: 'Überprüfen Sie Ihre E-Mail.',
          subtitle: null,
        },
        confirm: {
          title: 'Überprüfen Sie Ihre E-Mail.',
          subtitle: 'Wir haben einen sechsstelligen Bestätigungscode an {email} gesendet. Geben Sie den Code ein, bevor er abläuft.',
        },
        profile: {
          title: 'Willkommen bei Domo.',
          subtitle: 'Wir sind gerade dabei, Ihr Konto einzurichten. In der Zwischenzeit können Sie Ihr Domo-Profil vervollständigen.',
          progressLabel: 'Schritt 1 von 3',
          stepperLabel: 'Complete profile',
        },
        personal: {
          title: 'Stellen Sie sich vor.',
          subtitle: 'Jetzt, wo Sie sich angemeldet haben, können wir Ihr Erlebnis personalisieren.',
          progressLabel: 'Schritt 2 von 3',
          stepperLabel: 'Personalize',
        },
        walkthrough: {
          title: 'Möchten Sie eine persönliche Produktführung?',
          subtitle: null,
          progressLabel: 'Schritt 3 von 3',
          stepperLabel: 'Launch Domo',
        },
      },
    },

    // FREE
    free: {
      steps: {
        home: {
          eloquaFormName: 'Freemium_ConfirmationNumber',
          title: 'Testen Sie Domo kostenlos.',
          subtitle: 'Verbinden, transformieren und visualisieren Sie Ihre Daten in Domo. Erstellen Sie jetzt ein Domo-Konto.',
          features: [],
        },
      },
    },

    // FREEMIUM
    freemium: {
      steps: {
        home: {
          eloquaFormName: 'Freemium_ConfirmationNumber',
          title: 'Erstellen Sie jetzt ein kostenloses Konto – mit zeitlich unbegrenztem Zugriff.',
          subtitle: 'Alle Funktionen, unbegrenzte Anzahl von Benutzern, kein Zeitlimit – und keine Kreditkarte erforderlich.',
          features: [
            '<b>Unbegrenzte Anzahl Benutzer:</b> Die Anzahl der Plätze ist und bleibt unbegrenzt.',
            '<b>Alle Funktionen inbegriffen:</b> Sie haben Zugriff auf jede der über 50 Funktionen – KI-Dashboards, Datenintegration, Tools zur App-Erstellung, KI-Modellverwaltung, Sicherheit und mehr.',
            '<b>Mehr Handlungsfähigkeit für Mitarbeiter:</b>  Bieten Sie allen Benutzern den Zugriff auf Echtzeitdaten, um schnelle, intelligente Entscheidungen zu treffen, die das Unternehmen revolutionieren.',
            '<b>Flexible Preisgestaltung:</b> Wenn Ihnen die kostenlose Option irgendwann nicht mehr genügt, dann können Sie von budgetfreundlichen Preisen profitieren – je nachdem, wie intensiv Sie die Plattform nutzen.',
          ],
        },
      },
    },

    // SNOWFLAKE
    snowflake: {
      steps: {
        home: {
          eloquaFormName: 'Freemium_ConfirmationNumber',
          title: 'Try Domo for free.',
          subtitle: 'Simplify data management and get actionable intelligence faster with Snowflake and Domo.',
          features: [
            '<b>Rapid Setup:</b> Power up your Snowflake + Domo environment in minutes.',
            '<b>Connect and Enrich Data:</b> Enrich your Snowflake data foundation via Domo’s robust integration library of 1000+ connectors and on-premises capabilities.',
            '<b>Prepare Data for Analysis:</b> Quickly prepare data with robust ETL capabilities via push down SQL to Snowflake.',
            '<b>Turn Insights into Action:</b> Build action-oriented solutions via BI visualizations and low-code applications accessible to all users at no cost.',
          ],
        },
      },
    },

    // DEVELOPER
    developer: {
      steps: {
        home: {
          eloquaFormName: 'Freemium_ConfirmationNumber',
          title: 'Get your developer trial.',
          subtitle: 'Start your free trial to start developing on Domo today.',
          features: [
            'Full-featured, dev-ready environment',
            'Connector IDE and data API ready',
            'Pre-loaded with sample data & cards',
            'Extensive documentation and guides',
            'SDK for building custom apps',
          ],
          finePrint: 'Free for customers, partners, and prospective partners.<br/><br/>By requesting a developer trial you agree to <a class="underline" target="_blank" href="https://web-assets.domo.com/blog/wp-content/uploads/2021/09/Domo-Developer-Trial-Agreement-2021.pdf">Domo\'s terms of service.</a>',
        },
      },
    },

    // IBM
    ibm: {
      steps: {
        home: {
          eloquaFormName: 'Freemium_ConfirmationNumber',
          title: 'Testen Sie Domo kostenlos.',
          subtitle: 'Verbinden, transformieren und visualisieren Sie Ihre Daten in Domo. Erstellen Sie jetzt ein Domo-Konto.',
          features: [],
        },
        useCase: {
          title: 'Tell us about your use case.',
          subtitle: 'Domo can do a lot—help us understand what you hope to accomplish.',
          progressLabel: 'Schritt 3 von 3',
        },
      },
    },
  },

  // OPTIONS
  companySizes: [
    {
      value: '5000+',
      label: '5000+',
    },
    {
      value: '1000-4999',
      label: '1000-4999',
    },
    {
      value: '500-999',
      label: '500-999',
    },
    {
      value: '100-499',
      label: '100-499',
    },
    {
      value: '50-99',
      label: '50-99',
    },
    {
      value: '1-49',
      label: '1-49',
    },
  ],

  departments: [
    {
      value: 'BI/Analytics',
      label: 'BI/Analytics',
    },
    {
      value: 'IT/Engineering',
      label: 'IT/Technik',
    },
    {
      value: 'Finance',
      label: 'Finanzen',
    },
    {
      value: 'Human Resources',
      label: 'Personalabteilung',
    },
    {
      value: 'Marketing',
      label: 'Marketing',
    },
    {
      value: 'Operations',
      label: 'Betriebsabteilung',
    },
    {
      value: 'Sales',
      label: 'Verkaufsabteilung',
    },
    {
      value: 'Support/Services',
      label: 'Support/Services',
    },
    {
      value: 'Other',
      label: 'Sonstiges',
    },
  ],

  roles: [
    {
      value: 'Data Creator / Analyst',
      label: '<b>Data Creator/Analyst</b><br><span class="color-mute font-14">Erstellung und Bereitstellung von Berichten für andere als Analyst oder Datenexperte</span>',
      icon: 'graph-report',
    },
    {
      value: 'Data Consumer',
      label: '<b>Data Consumer</b><br><span class="color-mute font-14">Konsumiere in erster Linie von Anderen erstellte Berichte</span>',
      icon: 'graph-computer',
    },
    {
      value: 'Data Engineer',
      label: '<b>Data Engineer</b><br><span class="color-mute font-14">Integration, Erstellung und Verwaltung von Datenbeständen im gesamten Unternehmen</span>',
      icon: 'cogs',
    },
  ],

  useCases: [
    {
      value: 'Create real-time, interactive dashboards',
      label: 'Create real-time, interactive dashboards',
      icon: 'graph-computer',
    },
    {
      value: 'Make data more accessible for teams and execs',
      label: 'Make data more accessible for teams and execs',
      icon: 'users-circle',
    },
    {
      value: 'Integrate and analyze data from various sources',
      label: 'Integrate and analyze data from various sources',
      icon: 'graph-tree',
    },
    {
      value: 'Centralize all data and reporting',
      label: 'Centralize all data and reporting',
      icon: 'graph-line',
    },
    {
      value: 'Automate reporting',
      label: 'Automate reporting',
      icon: 'webpage',
    },
    {
      value: 'Provide dashboards to customers and partners',
      label: 'Provide dashboards to customers and partners',
      icon: 'hand-click',
    },
  ],

  poc: [
    {
      value: true,
      label: 'Ja, ich möchte eine kostenlose, persönliche Produktführung mit einem Domo-Spezialisten.',
    },
    {
      value: false,
      label: 'Nein, ich komme selbst zurecht.',
    },
  ],

  countries: [
    {
      value: 'DE',
      label: 'Deutschland',
    },
    {
      value: 'US',
      label: 'Vereinigte Staaten von Amerika',
    },
    {
      value: 'GB',
      label: 'Vereinigtes Königreich',
    },
    {
      value: 'CA',
      label: 'Kanada',
    },
    {
      value: 'JP',
      label: 'Japan',
    },
    {
      value: 'AF',
      label: 'Afghanistan',
    },
    {
      value: 'AX',
      label: 'Åland',
    },
    {
      value: 'AL',
      label: 'Albanien',
    },
    {
      value: 'DZ',
      label: 'Algerien',
    },
    {
      value: 'AS',
      label: 'Amerikanisch-Samoa',
    },
    {
      value: 'AD',
      label: 'Andorra',
    },
    {
      value: 'AO',
      label: 'Angola',
    },
    {
      value: 'AI',
      label: 'Anguilla',
    },
    {
      value: 'AQ',
      label: 'Antarktis',
    },
    {
      value: 'AG',
      label: 'Antigua und Barbuda',
    },
    {
      value: 'AR',
      label: 'Argentinien',
    },
    {
      value: 'AM',
      label: 'Armenien',
    },
    {
      value: 'AW',
      label: 'Aruba',
    },
    {
      value: 'AU',
      label: 'Australien',
    },
    {
      value: 'AT',
      label: 'Österreich',
    },
    {
      value: 'AZ',
      label: 'Aserbaidschan',
    },
    {
      value: 'BS',
      label: 'Bahamas',
    },
    {
      value: 'BH',
      label: 'Bahrain',
    },
    {
      value: 'BD',
      label: 'Bangladesch',
    },
    {
      value: 'BB',
      label: 'Barbados',
    },
    {
      value: 'BY',
      label: 'Weißrussland',
    },
    {
      value: 'BE',
      label: 'Belgien',
    },
    {
      value: 'BZ',
      label: 'Belize',
    },
    {
      value: 'BJ',
      label: 'Benin',
    },
    {
      value: 'BM',
      label: 'Bermuda',
    },
    {
      value: 'BT',
      label: 'Bhutan',
    },
    {
      value: 'BO',
      label: 'Bolivien',
    },
    {
      value: 'BA',
      label: 'Bosnien und Herzegowina',
    },
    {
      value: 'BW',
      label: 'Botswana',
    },
    {
      value: 'BV',
      label: 'Bouvetinsel',
    },
    {
      value: 'BR',
      label: 'Brasilien',
    },
    {
      value: 'IO',
      label: 'Britisches Territorium im Indischen Ozean',
    },
    {
      value: 'BN',
      label: 'Brunei Darussalam',
    },
    {
      value: 'BG',
      label: 'Bulgarien',
    },
    {
      value: 'BF',
      label: 'Burkina Faso',
    },
    {
      value: 'BI',
      label: 'Burundi',
    },
    {
      value: 'KH',
      label: 'Kambodscha',
    },
    {
      value: 'CM',
      label: 'Kamerun',
    },
    {
      value: 'CV',
      label: 'Cabo Verde',
    },
    {
      value: 'KY',
      label: 'Caymaninseln',
    },
    {
      value: 'CF',
      label: 'Zentralafrikanische Republik',
    },
    {
      value: 'TD',
      label: 'Tschad',
    },
    {
      value: 'CL',
      label: 'Chile',
    },
    {
      value: 'CN',
      label: 'China',
    },
    {
      value: 'CX',
      label: 'Weihnachtsinsel',
    },
    {
      value: 'CC',
      label: 'Kokosinseln (Keelinginseln)',
    },
    {
      value: 'CO',
      label: 'Kolumbien',
    },
    {
      value: 'KM',
      label: 'Komoren',
    },
    {
      value: 'CG',
      label: 'Kongo',
    },
    {
      value: 'CD',
      label: 'Kongo, Demokratische Republik',
    },
    {
      value: 'CK',
      label: 'Cookinseln',
    },
    {
      value: 'CR',
      label: 'Costa Rica',
    },
    {
      value: 'CI',
      label: 'Côte d\'Ivoire',
    },
    {
      value: 'HR',
      label: 'Kroatien',
    },
    {
      value: 'CU',
      label: 'Kuba',
    },
    {
      value: 'CY',
      label: 'Zypern',
    },
    {
      value: 'CZ',
      label: 'Tschechische Republik',
    },
    {
      value: 'DK',
      label: 'Dänemark',
    },
    {
      value: 'DJ',
      label: 'Dschibuti',
    },
    {
      value: 'DM',
      label: 'Dominica',
    },
    {
      value: 'DO',
      label: 'Dominikanische Republik',
    },
    {
      value: 'EC',
      label: 'Ecuador',
    },
    {
      value: 'EG',
      label: 'Ägypten',
    },
    {
      value: 'SV',
      label: 'El Salvador',
    },
    {
      value: 'GQ',
      label: 'Äquatorialguinea',
    },
    {
      value: 'ER',
      label: 'Eritrea',
    },
    {
      value: 'EE',
      label: 'Estland',
    },
    {
      value: 'ET',
      label: 'Äthiopien',
    },
    {
      value: 'FK',
      label: 'Falklandinseln (Malwinen)',
    },
    {
      value: 'FO',
      label: 'Färöer',
    },
    {
      value: 'FJ',
      label: 'Fidschi',
    },
    {
      value: 'FI',
      label: 'Finnland',
    },
    {
      value: 'FR',
      label: 'Frankreich',
    },
    {
      value: 'GF',
      label: 'Französisch-Guayana',
    },
    {
      value: 'PF',
      label: 'Französisch-Polynesien',
    },
    {
      value: 'TF',
      label: 'Französische Südgebiete',
    },
    {
      value: 'GA',
      label: 'Gabun',
    },
    {
      value: 'GM',
      label: 'Gambia',
    },
    {
      value: 'GE',
      label: 'Georgien',
    },
    {
      value: 'GH',
      label: 'Ghana',
    },
    {
      value: 'GI',
      label: 'Gibraltar',
    },
    {
      value: 'GR',
      label: 'Griechenland',
    },
    {
      value: 'GL',
      label: 'Grönland',
    },
    {
      value: 'GD',
      label: 'Grenada',
    },
    {
      value: 'GP',
      label: 'Guadeloupe',
    },
    {
      value: 'GU',
      label: 'Guam',
    },
    {
      value: 'GT',
      label: 'Guatemala',
    },
    {
      value: 'GG',
      label: 'Guernsey',
    },
    {
      value: 'GN',
      label: 'Guinea',
    },
    {
      value: 'GW',
      label: 'Guinea-Bissau',
    },
    {
      value: 'GY',
      label: 'Guyana',
    },
    {
      value: 'HT',
      label: 'Haiti',
    },
    {
      value: 'HM',
      label: 'Heard und McDonaldinseln',
    },
    {
      value: 'VA',
      label: 'Heiliger Stuhl (Vatikanstadt)',
    },
    {
      value: 'HN',
      label: 'Honduras',
    },
    {
      value: 'HK',
      label: 'Hongkong',
    },
    {
      value: 'HU',
      label: 'Ungarn',
    },
    {
      value: 'IS',
      label: 'Island',
    },
    {
      value: 'IN',
      label: 'Indien',
    },
    {
      value: 'ID',
      label: 'Indonesien',
    },
    {
      value: 'IR',
      label: 'Iran, Islamische Republik',
    },
    {
      value: 'IQ',
      label: 'Irak',
    },
    {
      value: 'IE',
      label: 'Irland',
    },
    {
      value: 'IM',
      label: 'Isle of Man',
    },
    {
      value: 'IL',
      label: 'Israel',
    },
    {
      value: 'IT',
      label: 'Italien',
    },
    {
      value: 'JM',
      label: 'Jamaika',
    },
    {
      value: 'JE',
      label: 'Jersey',
    },
    {
      value: 'JO',
      label: 'Jordanien',
    },
    {
      value: 'KZ',
      label: 'Kasachstan',
    },
    {
      value: 'KE',
      label: 'Kenia',
    },
    {
      value: 'KI',
      label: 'Kiribati',
    },
    {
      value: 'KP',
      label: 'Korea, Demokratische Volksrepublik',
    },
    {
      value: 'KR',
      label: 'Korea, Republik',
    },
    {
      value: 'KW',
      label: 'Kuwait',
    },
    {
      value: 'KG',
      label: 'Kirgisistan',
    },
    {
      value: 'LA',
      label: 'Laos, Demokratische Volksrepublik',
    },
    {
      value: 'LV',
      label: 'Lettland',
    },
    {
      value: 'LB',
      label: 'Libanon',
    },
    {
      value: 'LS',
      label: 'Lesotho',
    },
    {
      value: 'LR',
      label: 'Liberia',
    },
    {
      value: 'LY',
      label: 'Libysch-Arabische Dschamahirija',
    },
    {
      value: 'LI',
      label: 'Liechtenstein',
    },
    {
      value: 'LT',
      label: 'Litauen',
    },
    {
      value: 'LU',
      label: 'Luxemburg',
    },
    {
      value: 'MO',
      label: 'Macau',
    },
    {
      value: 'MK',
      label: 'Mazedonien',
    },
    {
      value: 'MG',
      label: 'Madagaskar',
    },
    {
      value: 'MW',
      label: 'Malawi',
    },
    {
      value: 'MY',
      label: 'Malaysia',
    },
    {
      value: 'MV',
      label: 'Malediven',
    },
    {
      value: 'ML',
      label: 'Mali',
    },
    {
      value: 'MT',
      label: 'Malta',
    },
    {
      value: 'MH',
      label: 'Marshallinseln',
    },
    {
      value: 'MQ',
      label: 'Martinique',
    },
    {
      value: 'MR',
      label: 'Mauretanien',
    },
    {
      value: 'MU',
      label: 'Mauritius',
    },
    {
      value: 'YT',
      label: 'Mayotte',
    },
    {
      value: 'MX',
      label: 'Mexiko',
    },
    {
      value: 'FM',
      label: 'Mikronesien, Föderierte Staaten',
    },
    {
      value: 'MD',
      label: 'Moldau, Republik',
    },
    {
      value: 'MC',
      label: 'Monaco',
    },
    {
      value: 'MN',
      label: 'Mongolei',
    },
    {
      value: 'ME',
      label: 'Montenegro',
    },
    {
      value: 'MS',
      label: 'Montserrat',
    },
    {
      value: 'MA',
      label: 'Marokko',
    },
    {
      value: 'MZ',
      label: 'Mosambik',
    },
    {
      value: 'MM',
      label: 'Myanmar',
    },
    {
      value: 'NA',
      label: 'Namibia',
    },
    {
      value: 'NR',
      label: 'Nauru',
    },
    {
      value: 'NP',
      label: 'Nepal',
    },
    {
      value: 'NL',
      label: 'Niederlande',
    },
    {
      value: 'AN',
      label: 'Niederländische Antillen',
    },
    {
      value: 'NC',
      label: 'Neukaledonien',
    },
    {
      value: 'NZ',
      label: 'Neuseeland',
    },
    {
      value: 'NI',
      label: 'Nicaragua',
    },
    {
      value: 'NE',
      label: 'Niger',
    },
    {
      value: 'NG',
      label: 'Nigeria',
    },
    {
      value: 'NU',
      label: 'Niue',
    },
    {
      value: 'NF',
      label: 'Norfolkinsel',
    },
    {
      value: 'MP',
      label: 'Nördliche Marianen',
    },
    {
      value: 'NO',
      label: 'Norwegen',
    },
    {
      value: 'OM',
      label: 'Oman',
    },
    {
      value: 'PK',
      label: 'Pakistan',
    },
    {
      value: 'PW',
      label: 'Palau',
    },
    {
      value: 'PS',
      label: 'Palästinensische Gebiete, besetzt',
    },
    {
      value: 'PA',
      label: 'Panama',
    },
    {
      value: 'PG',
      label: 'Papua-Neuguinea',
    },
    {
      value: 'PY',
      label: 'Paraguay',
    },
    {
      value: 'PE',
      label: 'Peru',
    },
    {
      value: 'PH',
      label: 'Philippinen',
    },
    {
      value: 'PN',
      label: 'Pitcairn',
    },
    {
      value: 'PL',
      label: 'Polen',
    },
    {
      value: 'PT',
      label: 'Portugal',
    },
    {
      value: 'PR',
      label: 'Puerto Rico',
    },
    {
      value: 'QA',
      label: 'Katar',
    },
    {
      value: 'RE',
      label: 'Réunion',
    },
    {
      value: 'RO',
      label: 'Rumänien',
    },
    {
      value: 'RU',
      label: 'Russische Föderation',
    },
    {
      value: 'RW',
      label: 'Ruanda',
    },
    {
      value: 'SH',
      label: 'St. Helena',
    },
    {
      value: 'KN',
      label: 'St. Kitts und Nevis',
    },
    {
      value: 'LC',
      label: 'St. Lucia',
    },
    {
      value: 'PM',
      label: 'Saint-Pierre und Miquelon',
    },
    {
      value: 'VC',
      label: 'St. Vincent und die Grenadinen',
    },
    {
      value: 'WS',
      label: 'Samoa',
    },
    {
      value: 'SM',
      label: 'San Marino',
    },
    {
      value: 'ST',
      label: 'São Tomé und Príncipe',
    },
    {
      value: 'SA',
      label: 'Saudi-Arabien',
    },
    {
      value: 'SN',
      label: 'Senegal',
    },
    {
      value: 'RS',
      label: 'Serbien',
    },
    {
      value: 'SC',
      label: 'Seychellen',
    },
    {
      value: 'SL',
      label: 'Sierra Leone',
    },
    {
      value: 'SG',
      label: 'Singapur',
    },
    {
      value: 'SK',
      label: 'Slowakei',
    },
    {
      value: 'SI',
      label: 'Slowenien',
    },
    {
      value: 'SB',
      label: 'Salomonen',
    },
    {
      value: 'SO',
      label: 'Somalia',
    },
    {
      value: 'ZA',
      label: 'Südafrika',
    },
    {
      value: 'GS',
      label: 'Südgeorgien',
    },
    {
      value: 'ES',
      label: 'Spanien',
    },
    {
      value: 'LK',
      label: 'Sri Lanka',
    },
    {
      value: 'SD',
      label: 'Sudan',
    },
    {
      value: 'SR',
      label: 'Suriname',
    },
    {
      value: 'SJ',
      label: 'Spitzbergen und Jan Mayen',
    },
    {
      value: 'SZ',
      label: 'Swasiland',
    },
    {
      value: 'SE',
      label: 'Schweden',
    },
    {
      value: 'CH',
      label: 'Schweiz',
    },
    {
      value: 'SY',
      label: 'Syrien, Arabische Republik',
    },
    {
      value: 'TW',
      label: 'Taiwan, Provinz der Volksrepublik China',
    },
    {
      value: 'TJ',
      label: 'Tadschikistan',
    },
    {
      value: 'TZ',
      label: 'Tansania, Vereinigte Republik',
    },
    {
      value: 'TH',
      label: 'Thailand',
    },
    {
      value: 'TL',
      label: 'Timor-Leste',
    },
    {
      value: 'TG',
      label: 'Togo',
    },
    {
      value: 'TK',
      label: 'Tokelau',
    },
    {
      value: 'TO',
      label: 'Tonga',
    },
    {
      value: 'TT',
      label: 'Trinidad und Tobago',
    },
    {
      value: 'TN',
      label: 'Tunesien',
    },
    {
      value: 'TR',
      label: 'Türkei',
    },
    {
      value: 'TM',
      label: 'Turkmenistan',
    },
    {
      value: 'TC',
      label: 'Turks- und Caicosinseln',
    },
    {
      value: 'TV',
      label: 'Tuvalu',
    },
    {
      value: 'UG',
      label: 'Uganda',
    },
    {
      value: 'UA',
      label: 'Ukraine',
    },
    {
      value: 'AE',
      label: 'Vereinigte Arabische Emirate',
    },
    {
      value: 'UM',
      label: 'Kleinere amerikanische Inseln',
    },
    {
      value: 'UY',
      label: 'Uruguay',
    },
    {
      value: 'UZ',
      label: 'Usbekistan',
    },
    {
      value: 'VU',
      label: 'Vanuatu',
    },
    {
      value: 'VE',
      label: 'Venezuela',
    },
    {
      value: 'VN',
      label: 'Vietnam',
    },
    {
      value: 'VG',
      label: 'Jungferninseln, Britisch',
    },
    {
      value: 'VI',
      label: 'Jungferninseln, US',
    },
    {
      value: 'WF',
      label: 'Wallis und Futuna',
    },
    {
      value: 'EH',
      label: 'Westsahara',
    },
    {
      value: 'YE',
      label: 'Jemen',
    },
    {
      value: 'ZM',
      label: 'Sambia',
    },
    {
      value: 'ZW',
      label: 'Simbabwe',
    },
  ],
};
