import de from '@/i18n/locales/de';
import en from '@/i18n/locales/en';
import es from '@/i18n/locales/es';
import fr from '@/i18n/locales/fr';
import it from '@/i18n/locales/it';
import ja from '@/i18n/locales/ja';

export default {
  de,
  en,
  es,
  fr,
  it,
  ja,
};
