import { ref } from 'vue';

// Global state
const toasts = ref([]);
const toastEater = ref(null);

export default function useToast() {
  function stopEating() {
    clearInterval(toastEater.value);
    toastEater.value = null;
  }

  function eatToast(toastId) {
    toasts.value = toasts.value.filter(({ id }) => id !== toastId);

    if (!toasts.value.length) stopEating();
  }

  function expireToast() {
    const expiredToasts = toasts.value.filter(({ id, duration }) => Date.now() > id + duration);
    return expiredToasts.forEach(({ id }) => eatToast(id));
  }


  function scheduleEat() {
    if (toastEater.value !== null) return;

    toastEater.value = setInterval(() => expireToast(), 100);
  }

  function makeToast({ message, duration = 4000, theme = 'error' }) {
    toasts.value.push({
      id: Date.now(),
      message,
      duration,
      theme,
    });

    return scheduleEat();
  }

  return {
    toasts,
    eatToast,
    makeToast,
  };
}
