export default [
  {
    icon: 'facebook',
    href: 'https://facebook.com/domoHQ/timeline/',
  },
  {
    icon: 'twitter',
    href: 'https://twitter.com/domotalk',
  },
  {
    icon: 'linkedin',
    href: 'https://linkedin.com/company/domo-inc-',
  },
  {
    icon: 'instagram',
    href: 'https://instagram.com/domo/',
  },
  {
    icon: 'youtube',
    href: 'https://youtube.com/user/DomoTechnologies',
  },
];
