<template>
  <svg
    class="IconBase"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :aria-labelledby="name"
    role="presentation">
    <g
      :fill="color">
      <component
        :is="computedIcon"
      />
    </g>
  </svg>
</template>


<script>
import { defineAsyncComponent } from 'vue';

export default {
  name: 'IconBase',

  props: {
    name: {
      type: String,
      required: true,
    },
    width: {
      type: [Number, String],
      default: 24,
    },
    height: {
      type: [Number, String],
      default: 24,
    },
    color: {
      type: String,
      default: 'currentColor',
    },
  },

  computed: {
    computedIcon() {
      const iconName = this.name;
      return defineAsyncComponent(() => import(`@/components/icon/${iconName}.vue`));
    },
  },
};
</script>


<style scoped>
.IconBase {
  display: block;
  margin: 0 auto;
}
</style>
