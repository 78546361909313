import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './i18n';
import http from './plugins/http';

const app = createApp(App);

// Remove with next minor release:
app.config.unwrapInjectedRef = true;

app
  .use(router)
  .use(i18n)
  .use(http)
  .mount('#app');
