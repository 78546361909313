import {
  ref,
  reactive,
  readonly,
} from 'vue';
import I18n from '@/i18n';
import Http from '@/services/http';
import useCountry from '@/use/country';
import useUtils from '@/use/utils';

const { isCountryInaccessible } = useCountry();
const { redirect } = useUtils();

// Properties
const isDomoLoaded = ref(false);
const domo = reactive({});


// Load Domo Environment
const loadDomo = async () => {
  if (isDomoLoaded.value) return domo;

  try {
    const { data } = await Http.get('/api/env');

    // Redirect blacklisted countries
    if (isCountryInaccessible(data.country)) return redirect(`${process.env.VUE_APP_API_BASE_URL}/form/free-instance`);

    // Set domo object
    // @TODO have Will pass domoId as string to refactor this:
    const {
      domoId,
      ...domoEnvKeys
    } = data;

    Object.assign(domo, {
      domoId: `${domoId}`,
      ...domoEnvKeys,
    });

    // Set on window (for third-parties)
    window.Domo = {
      domoId: `${domoId}`,
      ...domoEnvKeys,
      env: process.env.NODE_ENV,
    };

    // Pre-pop locale
    I18n.global.locale = domo.lang;
  } catch (error) {
    console.error(error);
  }

  isDomoLoaded.value = true;

  return domo;
};


// Pre-pop uffid
async function prePopUffid(session, route) {
  session.uffid = domo.uffid;

  let remoteSession = null;

  if (!route.query.uffid) {
    return remoteSession;
  }

  try {
    const { data } = await Http.get(`/api/free/restoreSession?uffid=${route.query.uffid}`);

    remoteSession = data;

    domo.uffid = route.query.uffid;
    session.uffid = route.query.uffid;
  } catch (error) {
    console.error('ERROR PRE-POPPING UFFID: ', error);
  }

  return remoteSession;
}


// Exports
export default function useDomo() {
  return {
    domo: readonly(domo),
    loadDomo,
    prePopUffid,
  };
}
