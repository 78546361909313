import {
  ref,
  computed,
  readonly,
} from 'vue';
import Http from '@/services/http';
import useToast from '@/use/toast';

const { makeToast } = useToast();


// Constants
const ALLOWED_FLOWS = process.env.VUE_APP_ALLOWED_FLOWS.split(',');
const { DEFAULT_FLOW } = process.env;

// Properties
const isFlowLoaded = ref(false);
const flowConfig = ref(null);
const currentStepId = ref(null);

const flow = computed(() => (flowConfig.value || null));

const currentStep = computed(() => (currentStepId.value && flow.value?.steps
  ? flow.value.steps.find((step) => step.id === currentStepId.value)
  : null
));

const nextStep = computed(() => {
  if (!flow.value?.steps || !currentStepId.value) return null;

  const currentStepIndex = flow.value.steps
    .findIndex((step) => step.id === currentStepId.value);

  return flow.value.steps[currentStepIndex + 1] || null;
});

const stepperSteps = computed(() => {
  if (!flow.value?.steps) return [];

  return flow.value.steps
    .filter((step) => !!step.stepperLabel)
    .map((step) => ({ id: step.id, label: step.stepperLabel }));
});


// Utils
const findStepByRouteName = (stepRouteName) => {
  if (!flow.value) return null;

  return flow.value.steps.find((step) => (stepRouteName
    ? step.routeName === stepRouteName
    : step.routeName === null)) || null;
};

const isFlowNameValid = (flowName) => flowName && ALLOWED_FLOWS.includes(flowName);

const isStepRouteNameValid = (stepRouteName) => {
  if (!flow.value?.steps) return false;

  return flow.value.steps.some((step) => step.routeName === stepRouteName);
};

const precedingSteps = (flowName, stepRouteName) => {
  if (!flow.value?.steps) return [];

  const thisStepIndex = flow.value.steps.findIndex((step) => step.routeName === stepRouteName);

  return flow.value.steps.slice(0, thisStepIndex);
};

const isStepValidClientSide = (stepRouteName, session) => {
  if (!isStepRouteNameValid(stepRouteName)) return false;

  const flowStep = findStepByRouteName(stepRouteName);

  return !flowStep.fields
    .map((f) => session[f.name].isValidClientSide)
    .some((isFieldValid) => !isFieldValid);
};

const firstUnauthorizedPrecedingStep = (flowName, stepRouteName, session) => {
  let firstUnauthorizedStep = null;

  const thisStep = findStepByRouteName(stepRouteName);
  const preReqSteps = precedingSteps(flowName, stepRouteName);

  const firstStepReqs = preReqSteps[0]?.fields
    ? preReqSteps[0].fields.map((f) => f.name)
    : [];

  const doesThisStepContainFirstStepReqs = firstStepReqs
    .some((fieldName) => thisStep.fields.map((f) => f.name).includes(fieldName));

  if (doesThisStepContainFirstStepReqs) return firstUnauthorizedStep;

  preReqSteps.forEach((preReqStep) => {
    if (firstUnauthorizedStep) return;

    const isThisStepValid = isStepValidClientSide(preReqStep.routeName, session);

    if (!isThisStepValid) {
      firstUnauthorizedStep = preReqStep;
    }
  });

  return firstUnauthorizedStep;
};

const loadFlowModule = async (flowName) => {
  try {
    const module = await import(`@/flows/${flowName}`);
    return module.default();
  } catch (error) {
    console.error(`ERROR LOADING FLOW - ${flowName}: `, error);
    return null;
  }
};

const doesFlowModulePassGuards = (module, route) => {
  const { guards } = module;

  if (guards && guards.beforeLoad) {
    return guards.beforeLoad(route);
  }

  return true;
};

const abortFlow = (fallbackFlowId = null, lang = '', route) => {
  const isFallbackFlow = fallbackFlowId && isFlowNameValid(fallbackFlowId);

  return {
    flow: null,
    flowRedirect: {
      name: 'step',
      params: {
        lang,
        flowName: isFallbackFlow ? fallbackFlowId : DEFAULT_FLOW,
      },
      query: route.query,
      replace: true,
    },
  };
};

// Load Flow
const loadFlow = async (requestedFlowName, route) => {
  const flowRedirect = null;
  const lang = route?.params?.lang || '';

  if (isFlowLoaded.value) {
    return {
      flow: flow.value,
      flowRedirect,
    };
  }

  if (!isFlowNameValid(requestedFlowName)) return abortFlow(null, lang, route);

  const requestedFlowModule = await loadFlowModule(requestedFlowName);

  if (!doesFlowModulePassGuards(requestedFlowModule, route)) {
    return abortFlow(requestedFlowModule.fallbackFlowId, lang, route);
  }

  flowConfig.value = requestedFlowModule;

  const flowToLoadFirstStep = flow.value?.steps[0] || null;

  if (!flowConfig.value || !flowToLoadFirstStep) {
    return abortFlow(requestedFlowModule.fallbackFlowId, lang, route);
  }

  isFlowLoaded.value = true;

  return {
    flow: flow.value,
    flowRedirect,
  };
};


// Authorize Step
const authorizeStep = async (route, flowName, stepRouteName, session) => {
  const stepName = stepRouteName || null;
  const lang = route.params.lang || '';

  // 1) Check for valid stepName
  if (!isStepRouteNameValid(stepName)) {
    const fallbackStepName = flow.value.steps[0].routeName
      ? flow.value.steps[0].routeName
      : '';

    console.error(`
      ATTEMPT TO ACCESS INVALID STEP - ${stepRouteName}:
      Redirect to: /${flowName}/${fallbackStepName}
    `);

    return {
      stepRedirect: {
        name: 'step',
        params: {
          lang,
          flowName,
          stepName: fallbackStepName,
        },
        query: route.query,
        replace: true,
      },
    };
  }

  // 2) Check for valid authToken if route !isPublic
  const thisStep = findStepByRouteName(stepRouteName);
  const stepRequiresAuth = !thisStep.isPublic;

  if (stepRequiresAuth) {
    try {
      await Http.get('/api/free/v2/tokenCheck');
    } catch (error) {
      const errorMessage = (error.isAxiosError && error.response.status === 401)
        ? '<b>Session Expired</b><br/>This is most likely due to inactivity. For security purposes, please complete your sign-up process in less than one hour.'
        : '<b>Unexpected Error</b><br/>An unexpected error occured, please try again later. If this continues, please contact us.';

      // Show error in UI
      makeToast({
        duration: 6000,
        message: errorMessage,
        theme: 'error',
      });

      // Remove expired token (if any)
      localStorage.removeItem(process.env.VUE_APP_ACCESS_TOKEN_NAME);

      // Reset the remote session
      try {
        await Http.post('/api/free/clearSession');
      } catch {
        console.error('Error clearing session in use/flow');
      }

      // Reset the local session
      session.code.value = session.code.defaultValue;
      session.password.value = session.password.defaultValue;

      // Redirect back to first step
      return {
        stepRedirect: {
          name: 'step',
          params: {
            lang,
            flowName,
          },
          query: route.query,
          replace: true,
        },
      };
    }
  }

  // 3) Check previous steps are valid (redirect to first unauthorized step)
  const unauthorizedPrecedingStep = firstUnauthorizedPrecedingStep(
    flowName,
    stepName,
    session,
  );

  if (unauthorizedPrecedingStep) {
    const unauthorizedPrecedingStepName = unauthorizedPrecedingStep.routeName
      ? unauthorizedPrecedingStep.routeName
      : '';

    console.error(`
      ATTEMPT TO ACCESS UNAUTHORIZED STEP - ${stepName}:
      Redirect to: /${flowName}/${unauthorizedPrecedingStepName}
    `);

    return {
      stepRedirect: {
        name: 'step',
        params: {
          lang,
          flowName,
          stepName: unauthorizedPrecedingStepName,
        },
        query: route.query,
        replace: true,
      },
    };
  }

  // 3) Step is authorized (no redirect)
  return {
    stepRedirect: null,
  };
};


// Set Current Step
const setCurrentStepId = (stepRouteName) => {
  const stepName = stepRouteName || null;

  if (!isStepRouteNameValid(stepName)) return;

  const thisStep = findStepByRouteName(stepName);

  currentStepId.value = thisStep.id;
};


// Exports
export default function useFlow() {
  return {
    flow,
    loadFlow,
    isFlowLoaded: readonly(isFlowLoaded),
    authorizeStep,
    currentStep,
    nextStep,
    stepperSteps,
    setCurrentStepId,
    isStepValidClientSide,
  };
}
