export default {
  // CTAS
  ctas: {
    alreadyDomoUser: 'すでにアカウントをお持ちの方：',
    createAccount: '新規登録',
    launchDomoNow: '完了',
    next: '次へ',
    finishAndLaunchDomo: '完了',
    signIn: 'ログイン',
    signUp: '新規登録',
  },

  // SHARED
  shared: {
    businessEmail: 'ビジネス用メール',
    businessEmailError: '有効なビジネス用のメールアドレスを入力してください',
    changeEmail: 'メールアドレスを変更',
    checkSpam: '迷惑メールフォルダをご確認ください',
    clickToResend: '再送信',
    company: '会社名',
    companyNameIsRequired: '*会社名を入力してください',
    confirmationCode: '確認コード',
    confirmationCodeError: 'そのコードは、送信したコードと一致しません {email}。もう一度お試しください。',
    contactForAssistance: "サポートが必要な場合は、 <a class='color-link-blue link' href='mailto:domofree{'@'}domo.com' target='_blank'>domofree{'@'}domo.com</a> までご連絡ください。",
    country: '国または地域',
    departmentOrTeam: '所属する部門名',
    firstName: '名',
    firstNameIsRequired: '*名は必須です',
    jobTitle: '役職名',
    jobTitleIsRequired: '*役職名は必須です',
    lastName: '姓',
    lastNameIsRequired: '*姓は必須です',
    launchingDomo: 'Domoを起動中',
    launchingInSeconds: '残り{seconds}秒でDomoが起動します',
    notReceivedEmail: 'メールが受信できませんか？',
    notSeeingYourRole: 'その他：',
    notSeeingYourTeam: 'その他：',
    notSeeingYourUseCase: 'お使いのユースケースが表示されない場合は？',
    password: 'パスワード',
    passwordLowercase: '1文字以上の小文字が必須です',
    passwordLowercaseRequired: '*1文字以上の小文字が必須です',
    passwordMinimum: '8文字以上が必須です',
    passwordMinimumRequired: '*8文字以上が必須です',
    passwordNumber: '1文字以上の数字が必須です',
    passwordNumberRequired: '*1文字以上の数字が必須です',
    passwordRequirements: "パスワードは最低8文字で、大文字と小文字、数字、そして特使文字（{'! @ # $'}）が最低1文字含まれている必要があります。",
    passwordRequirementsLabel: 'パスワードには以下を含む必要があります：',
    passwordSpecial: "1文字以上の記号　例：{'@$!#'",
    passwordSpecialRequired: '1文字以上の記号が必須です',
    passwordUppercase: '1文字以上の大文字が必須です',
    passwordUppercaseRequired: '*1文字以上の大文字が必須です',
    phone: '電話番号',
    pleaseCompleteFields: 'すべての必須項目を入力してください',
    pleaseEnterValidPhoneNumber: '*電話番号を入力して下さい',
    primaryObjectiveRequired: '*主な目的は必須です',
    rateLimitError: 'リクエストに失敗した回数が多すぎます。速度が制限されています。後でもう一度お試しください。',
    resendsRemaining: '(残りの試行回数 {attempts} 回)',
    resendError: '確認コードを再送信できません。後でもう一度お試しください。',
    resendingCode: '確認コードを再送信',
    resentCode: '確認コードが送信されました',
    role: '利用用途',
    roleIsRequired: '*利用用途は必須です',
    search: '検索',
    teamIsRequired: '*部門は必須です',
    tellUsHowYouWantToUseDomo: 'Domoの使用方法を教えてください',
    tellUsWhatYouDo: '利用用途を入力',
    trustedBy: '世界の革新的な企業がDomoを採用しています',
    useCase: 'ユースケース',
    walkthroughIsRequired: '無料サポートのご利用を選択ください',
    wantToScheduleLater: 'スケジュール選択をスキップする',
    whatBestDescribesYourRole: 'Domoの利用用途',
    whatIsYourJobTitle: '役職名を入力してください',
    whatTeamAreYouOn: '部門名を入力',
    whatTeamDoYouWorkOn: '所属する部門',
    whatWouldYouLikeToAccomplish: 'Domoを使用して何を解決したいですか？',
    wrongAddress: '登録アドレス {email} を変更する場合：',

  },

  // LEGAL
  legal: {
    acknowledgePrivacyPolicy: '作成することで、Domoの<a class="__legal-link font-semi-bold nowrap" rel="nofollow noopener noreferrer" href="https://www.domo.com/jp/company/privacy-policy" target="_blank">プライバシーポリシー</a>を理解し、同意したことになります。',
    agreeToTerms: '次へ進めることで、Domoの<a class="underline" href="https://www.domo.com/jp/company/service-terms" target="_blank">サービス利用規約</a>および<a class="underline" href="https://www.domo.com/jp/company/privacy-policy" target="_blank">プライバシーポリシー</a>に同意したことになります。',
    autoAgreeToTerms: '「次へ」をクリックすると、<a class="underline" href="https://www.domo.com/jp/company/service-terms" target="_blank">利用規約</a> および <a class="underline" href="https://www.domo.com/jp/company/privacy-policy" target="_blank">プライバシーとCookieポリシー</a> に同意したものとみなされます。',
    autoConsent: 'ビジネス用のDomo製品とサービスに関する詳細や、お得な情報をDomoよりお送りすることがあります。',
    managePreferences: '同意を取り消す、またはサブスクリプション設定を管理するには、<a class="underline" href="https://www.domo.com/jp/subscription" target="_blank">こちら</a> をクリックしてください。',
    patents: '特許',
    privacyCookiePolicy: 'プライバシーとCookieポリシー',
    privacyNotice: 'プライバシー',
    requestConsent: 'Domoのビジネス向けの製品とサービスに関する詳細や、お得な情報をDomoが送信するのを許可します。',
    termsOfService: '利用規約',
  },

  // PRIVACY LINKS
  privacies: [
    {
      label: 'Cookie Preferences',
      href: 'https://domo.com/jp/company/cookies',
    },
    {
      label: 'プライバシー＆Cookieポリシー',
      href: 'https://domo.com/jp/company/privacy-policy',
    },
    {
      label: '特許',
      href: 'https://domo.com/jp/company/patents',
    },
  ],

  // FLOWS
  flows: {

    // SHARED
    shared: {
      steps: {
        email: {
          title: 'Domo無償トライアル',
          subtitle: null,
        },
        confirm: {
          title: 'メールをご確認ください',
          subtitle: '6桁の確認コードを送信しました {email}。期限切れになる前に入力してください。',
        },
        profile: {
          title: 'Domoへようこそ',
          subtitle: 'まず最初にプロフィールを登録してください。',
          progressLabel: 'ステップ1',
          stepperLabel: 'Complete profile',
        },
        personal: {
          title: 'パーソナライズ情報の登録',
          subtitle: 'ご登録いただいた内容に合わせて情報をご提供いたします。',
          progressLabel: 'ステップ2',
          stepperLabel: 'Personalize',
        },
        walkthrough: {
          title: 'Domoの初期設定や使い方に関する無料のサポートを受けられます。',
          subtitle: null,
          progressLabel: 'ステップ3',
          stepperLabel: 'Launch Domo',
        },
      },
    },

    // FREE
    free: {
      steps: {
        home: {
          eloquaFormName: 'Freemium_ConfirmationNumber',
          title: 'Domo 無償トライアル',
          subtitle: 'インストール、ソフトウェアのダウンロード不要で、実際のDomoに搭載される機能を無料で30日間お試しいただけます。',
          features: [
            '<b>可視化と分析：</b> KPIをリアルタイムで作成、共有できます。',
            '<b>レポートとダッシュボード：</b> あらゆるデータを見える化します。',
            '<b>豊富なテンプレート：</b> 250種類以上のグラフやダッシュボード、1000以上のデータコネクターで簡単にすぐご利用いただけます。',
            '<b>全機能利用可能：</b> コネクター、ETL、可視化、レポート、AI・機械学習、アプリなど、全ての機能をご利用いただけます。',
            '<b>サポート体制：</b> 無料のオンラインサポートとマンツーマンサポートも利用いただけます。',
            '<b>モバイル対応：</b> あらゆるデバイスでご利用いただけます。',
          ],
        },
      },
    },

    // FREEMIUM
    freemium: {
      steps: {
        home: {
          eloquaFormName: 'Freemium_ConfirmationNumber',
          title: 'メールアドレスを入力して<br>今すぐ無料で作成',
          subtitle: 'すべての機能を無料でご利用いただけます。登録は勤務先メールアドレスがおすすめです。',
          features: [
            '<b>個人でもチームでも：</b>共有・共同作業に必要な人の追加は無制限',
            '<b>すべての機能にアクセス：</b>データの保存や加工、250以上のテンプレートを含むグラフやダッシュボードの可視化、AI予測やレポート配信など、必要な機能を自由に利用可能',
            '<b>自動でリアルタイム分析：</b>1,000以上のデータソース（セールスフォース、Googleアナリティクス、X、AWS、Excelなど）と連携',
            '<b>どこからでもアクセス：</b>PCやモバイルアプリなど、端末を選ばず、職場でも外出先でもアクセス可能',
          ],
        },
      },
    },

    // SNOWFLAKE
    snowflake: {
      steps: {
        home: {
          eloquaFormName: 'Freemium_ConfirmationNumber',
          title: 'Try Domo for free.',
          subtitle: 'Simplify data management and get actionable intelligence faster with Snowflake and Domo.',
          features: [
            '<b>Rapid Setup:</b> Power up your Snowflake + Domo environment in minutes.',
            '<b>Connect and Enrich Data:</b> Enrich your Snowflake data foundation via Domo’s robust integration library of 1000+ connectors and on-premises capabilities.',
            '<b>Prepare Data for Analysis:</b> Quickly prepare data with robust ETL capabilities via push down SQL to Snowflake.',
            '<b>Turn Insights into Action:</b> Build action-oriented solutions via BI visualizations and low-code applications accessible to all users at no cost.',
          ],
        },
      },
    },

    // DEVELOPER
    developer: {
      steps: {
        home: {
          eloquaFormName: 'Freemium_ConfirmationNumber',
          title: 'Get your developer trial.',
          subtitle: 'Start your free trial to start developing on Domo today.',
          features: [
            'Full-featured, dev-ready environment',
            'Connector IDE and data API ready',
            'Pre-loaded with sample data & cards',
            'Extensive documentation and guides',
            'SDK for building custom apps',
          ],
          finePrint: 'Free for customers, partners, and prospective partners.<br/><br/>By requesting a developer trial you agree to <a class="underline" target="_blank" href="https://web-assets.domo.com/blog/wp-content/uploads/2021/09/Domo-Developer-Trial-Agreement-2021.pdf">Domo\'s terms of service.</a>',
        },
      },
    },

    // IBM
    ibm: {
      steps: {
        home: {
          eloquaFormName: 'JP_Freemium_7324_Confirm',
          title: 'Domo 無償トライアル',
          subtitle: 'インストール、ソフトウェアのダウンロード不要で、実際のDomoに搭載される機能を無料で30日間お試しいただけます。',
          features: [
            '<b>可視化と分析：</b> KPIをリアルタイムで作成、共有できます。',
            '<b>レポートとダッシュボード：</b> あらゆるデータを見える化します。',
            '<b>豊富なテンプレート：</b> 250種類以上のグラフやダッシュボード、1000以上のデータコネクターで簡単にすぐご利用いただけます。',
            '<b>全機能利用可能：</b> コネクター、ETL、可視化、レポート、AI・機械学習、アプリなど、全ての機能をご利用いただけます。',
            '<b>サポート体制：</b> 無料のオンラインサポートとマンツーマンサポートも利用いただけます。',
            '<b>モバイル対応：</b> あらゆるデバイスでご利用いただけます。',
          ],
        },
        useCase: {
          title: 'ユースケースについて教えてください',
          subtitle: 'Domo can do a lot—help us understand what you hope to accomplish.',
          progressLabel: 'ステップ3',
        },
      },
    },
  },

  // OPTIONS
  companySizes: [
    {
      value: '5000+',
      label: '5000名以上',
    },
    {
      value: '1000-4999',
      label: '1000-4999名',
    },
    {
      value: '500-999',
      label: '500-999名',
    },
    {
      value: '100-499',
      label: '100-499名',
    },
    {
      value: '50-99',
      label: '50-99名',
    },
    {
      value: '1-49',
      label: '1-49名',
    },
  ],

  departments: [
    {
      value: 'BI／データ分析',
      label: 'BI／データ分析',
    },
    {
      value: 'IT／エンジニア',
      label: 'IT／エンジニア',
    },
    {
      value: '経理',
      label: '経理',
    },
    {
      value: '人事',
      label: '人事',
    },
    {
      value: 'マーケティング',
      label: 'マーケティング',
    },
    {
      value: 'DX／経営企画',
      label: 'DX／経営企画',
    },
    {
      value: '営業',
      label: '営業',
    },
    {
      value: 'カスタマーサポート',
      label: 'カスタマーサポート',
    },
  ],

  roles: [
    {
      value: 'データやグラフの作成',
      label: '<b>データやグラフの作成</b><br><span class="color-mute font-14">データの取り込み、グラフ、ダッシュボード、レポート作成</span>',
      icon: 'graph-report',
    },
    {
      value: 'データの閲覧や分析',
      label: '<b>データの閲覧や分析</b><br><span class="color-mute font-14">ダッシュボードやレポートの閲覧および分析</span>',
      icon: 'graph-computer',
    },
    {
      value: 'データ基盤やAIエンジニア',
      label: '<b>データ基盤やAIエンジニア</b><br><span class="color-mute font-14">DX、データ活用、AIなどの管理</span>',
      icon: 'cogs',
    },
  ],

  useCases: [
    {
      value: 'Create real-time, interactive dashboards',
      label: 'Create real-time, interactive dashboards',
      icon: 'graph-computer',
    },
    {
      value: 'Make data more accessible for teams and execs',
      label: 'Make data more accessible for teams and execs',
      icon: 'users-circle',
    },
    {
      value: 'Integrate and analyze data from various sources',
      label: 'Integrate and analyze data from various sources',
      icon: 'graph-tree',
    },
    {
      value: 'Centralize all data and reporting',
      label: 'Centralize all data and reporting',
      icon: 'graph-line',
    },
    {
      value: 'Automate reporting',
      label: 'Automate reporting',
      icon: 'webpage',
    },
    {
      value: 'Provide dashboards to customers and partners',
      label: 'Provide dashboards to customers and partners',
      icon: 'hand-click',
    },
  ],

  poc: [
    {
      value: true,
      label: '無料サポートを希望する',
    },
    {
      value: false,
      label: '無料サポートを希望しない',
    },
  ],

  countries: [
    {
      value: 'JP',
      label: '日本',
    },
    {
      value: 'US',
      label: 'アメリカ合衆国',
    },
    {
      value: 'GB',
      label: 'イギリス',
    },
    {
      value: 'CA',
      label: 'カナダ',
    },
    {
      value: 'DE',
      label: 'ドイツ',
    },
    {
      value: 'AF',
      label: 'アフガニスタン',
    },
    {
      value: 'AX',
      label: 'オーランド諸島',
    },
    {
      value: 'AL',
      label: 'アルバニア',
    },
    {
      value: 'DZ',
      label: 'アルジェリア',
    },
    {
      value: 'AS',
      label: '米サモア',
    },
    {
      value: 'AD',
      label: 'アンドラ',
    },
    {
      value: 'AO',
      label: 'アンゴラ',
    },
    {
      value: 'AI',
      label: 'アングィラ',
    },
    {
      value: 'AQ',
      label: '南極大陸',
    },
    {
      value: 'AG',
      label: 'アンチグアバーブーダ',
    },
    {
      value: 'AR',
      label: 'アルゼンチン',
    },
    {
      value: 'AM',
      label: 'アルメニア',
    },
    {
      value: 'AW',
      label: 'アルバ',
    },
    {
      value: 'AU',
      label: 'オーストラリア',
    },
    {
      value: 'AT',
      label: 'オーストリア',
    },
    {
      value: 'AZ',
      label: 'アゼルバイジャン',
    },
    {
      value: 'BS',
      label: 'バハマ',
    },
    {
      value: 'BH',
      label: 'バーレーン',
    },
    {
      value: 'BD',
      label: 'バングラデシュ',
    },
    {
      value: 'BB',
      label: 'バルバドス',
    },
    {
      value: 'BY',
      label: 'ベラルーシ',
    },
    {
      value: 'BE',
      label: 'ベルギー',
    },
    {
      value: 'BZ',
      label: 'ベリーズ',
    },
    {
      value: 'BJ',
      label: 'ベナン',
    },
    {
      value: 'BM',
      label: 'バミューダ',
    },
    {
      value: 'BT',
      label: 'ブータン',
    },
    {
      value: 'BO',
      label: 'ボリビア',
    },
    {
      value: 'BA',
      label: 'ボスニア·ヘルツェゴビナ',
    },
    {
      value: 'BW',
      label: 'ボツワナ',
    },
    {
      value: 'BV',
      label: 'ブーベ島',
    },
    {
      value: 'BR',
      label: 'ブラジル',
    },
    {
      value: 'IO',
      label: 'イギリス領インド洋地域',
    },
    {
      value: 'BN',
      label: 'ブルネイ·ダルサラーム国',
    },
    {
      value: 'BG',
      label: 'ブルガリア',
    },
    {
      value: 'BF',
      label: 'ブルキナファソ',
    },
    {
      value: 'BI',
      label: 'ブルンジ',
    },
    {
      value: 'KH',
      label: 'カンボジア',
    },
    {
      value: 'CM',
      label: 'カメルーン',
    },
    {
      value: 'CV',
      label: 'カーボベルデ',
    },
    {
      value: 'KY',
      label: 'ケイマン諸島',
    },
    {
      value: 'CF',
      label: '中央アフリカ共和国',
    },
    {
      value: 'TD',
      label: 'チャド',
    },
    {
      value: 'CL',
      label: 'チリ',
    },
    {
      value: 'CN',
      label: '中国',
    },
    {
      value: 'CX',
      label: 'クリスマス島',
    },
    {
      value: 'CC',
      label: 'ココス諸島',
    },
    {
      value: 'CO',
      label: 'コロンビア',
    },
    {
      value: 'KM',
      label: 'コモロ',
    },
    {
      value: 'CG',
      label: 'コンゴ',
    },
    {
      value: 'CD',
      label: 'コンゴ民主共和国',
    },
    {
      value: 'CK',
      label: 'クック諸島',
    },
    {
      value: 'CR',
      label: 'コスタリカ',
    },
    {
      value: 'CI',
      label: 'コートジボワール',
    },
    {
      value: 'HR',
      label: 'クロアチア',
    },
    {
      value: 'CU',
      label: 'キューバ',
    },
    {
      value: 'CY',
      label: 'キプロス',
    },
    {
      value: 'CZ',
      label: 'チェコ共和国',
    },
    {
      value: 'DK',
      label: 'デンマーク',
    },
    {
      value: 'DJ',
      label: 'ジブチ',
    },
    {
      value: 'DM',
      label: 'ドミニカ',
    },
    {
      value: 'DO',
      label: 'ドミニカ共和国',
    },
    {
      value: 'EC',
      label: 'エクアドル',
    },
    {
      value: 'EG',
      label: 'エジプト',
    },
    {
      value: 'SV',
      label: 'エルサルバドル',
    },
    {
      value: 'GQ',
      label: '赤道ギニア',
    },
    {
      value: 'ER',
      label: 'エリトリア',
    },
    {
      value: 'EE',
      label: 'エストニア',
    },
    {
      value: 'ET',
      label: 'エチオピア',
    },
    {
      value: 'FK',
      label: 'フォークランド諸島（マルビナス諸島）',
    },
    {
      value: 'FO',
      label: 'フェロー諸島',
    },
    {
      value: 'FJ',
      label: 'フィジー',
    },
    {
      value: 'FI',
      label: 'フィンランド',
    },
    {
      value: 'FR',
      label: 'フランス',
    },
    {
      value: 'GF',
      label: '仏領ギアナ',
    },
    {
      value: 'PF',
      label: 'フランス領ポリネシア',
    },
    {
      value: 'TF',
      label: 'フランス南部領',
    },
    {
      value: 'GA',
      label: 'ガボン',
    },
    {
      value: 'GM',
      label: 'ガンビア',
    },
    {
      value: 'GE',
      label: 'グルジア',
    },
    {
      value: 'GH',
      label: 'ガーナ',
    },
    {
      value: 'GI',
      label: 'ジブラルタル',
    },
    {
      value: 'GR',
      label: 'ギリシャ',
    },
    {
      value: 'GL',
      label: 'グリーンランド',
    },
    {
      value: 'GD',
      label: 'グレナダ',
    },
    {
      value: 'GP',
      label: 'グアドループ島',
    },
    {
      value: 'GU',
      label: 'グアム島',
    },
    {
      value: 'GT',
      label: 'グアテマラ',
    },
    {
      value: 'GG',
      label: 'ガーンジー島',
    },
    {
      value: 'GN',
      label: 'ギニア',
    },
    {
      value: 'GW',
      label: 'ギニアビサウ',
    },
    {
      value: 'GY',
      label: 'ガイアナ',
    },
    {
      value: 'HT',
      label: 'ハイチ',
    },
    {
      value: 'HM',
      label: 'ハード島とマクドナルド諸島',
    },
    {
      value: 'VA',
      label: 'ローマ法王庁（バチカン市国）',
    },
    {
      value: 'HN',
      label: 'ホンジュラス',
    },
    {
      value: 'HK',
      label: '香港',
    },
    {
      value: 'HU',
      label: 'ハンガリー',
    },
    {
      value: 'IS',
      label: 'アイスランド',
    },
    {
      value: 'IN',
      label: 'インド',
    },
    {
      value: 'ID',
      label: 'インドネシア',
    },
    {
      value: 'IR',
      label: 'イラン、イスラム共和国',
    },
    {
      value: 'IQ',
      label: 'イラク',
    },
    {
      value: 'IE',
      label: 'アイルランド',
    },
    {
      value: 'IM',
      label: 'マン島',
    },
    {
      value: 'IL',
      label: 'イスラエル',
    },
    {
      value: 'IT',
      label: 'イタリア',
    },
    {
      value: 'JM',
      label: 'ジャマイカ',
    },
    {
      value: 'JE',
      label: 'ジャージ',
    },
    {
      value: 'JO',
      label: 'ヨルダン',
    },
    {
      value: 'KZ',
      label: 'カザフスタン',
    },
    {
      value: 'KE',
      label: 'ケニア',
    },
    {
      value: 'KI',
      label: 'キリバス',
    },
    {
      value: 'KP',
      label: '韓国、民主主義人民共和国',
    },
    {
      value: 'KR',
      label: '韓国、共和国の',
    },
    {
      value: 'KW',
      label: 'クウェート',
    },
    {
      value: 'KG',
      label: 'キルギスタン',
    },
    {
      value: 'LA',
      label: 'ラオス人民民主共和国',
    },
    {
      value: 'LV',
      label: 'ラトビア',
    },
    {
      value: 'LB',
      label: 'レバノン',
    },
    {
      value: 'LS',
      label: 'レソト',
    },
    {
      value: 'LR',
      label: 'リベリア',
    },
    {
      value: 'LY',
      label: 'リビア',
    },
    {
      value: 'LI',
      label: 'リヒテンシュタイン',
    },
    {
      value: 'LT',
      label: 'リトアニア',
    },
    {
      value: 'LU',
      label: 'ルクセンブルク',
    },
    {
      value: 'MO',
      label: 'マカオ',
    },
    {
      value: 'MK',
      label: 'マケドニア',
    },
    {
      value: 'MG',
      label: 'マダガスカル',
    },
    {
      value: 'MW',
      label: 'マラウイ',
    },
    {
      value: 'MY',
      label: 'マレーシア',
    },
    {
      value: 'MV',
      label: 'モルディブ',
    },
    {
      value: 'ML',
      label: 'マリ',
    },
    {
      value: 'MT',
      label: 'マルタ',
    },
    {
      value: 'MH',
      label: 'マーシャル諸島',
    },
    {
      value: 'MQ',
      label: 'マルティニーク島',
    },
    {
      value: 'MR',
      label: 'モーリタニア',
    },
    {
      value: 'MU',
      label: 'モーリシャス',
    },
    {
      value: 'YT',
      label: 'マヨット島',
    },
    {
      value: 'MX',
      label: 'メキシコ',
    },
    {
      value: 'FM',
      label: 'ミクロネシア',
    },
    {
      value: 'MD',
      label: 'モルドバ共和国',
    },
    {
      value: 'MC',
      label: 'モナコ',
    },
    {
      value: 'MN',
      label: 'モンゴル国',
    },
    {
      value: 'ME',
      label: 'モンテネグロ',
    },
    {
      value: 'MS',
      label: 'モントセラト',
    },
    {
      value: 'MA',
      label: 'モロッコ',
    },
    {
      value: 'MZ',
      label: 'モザンビーク',
    },
    {
      value: 'MM',
      label: 'ミャンマー',
    },
    {
      value: 'NA',
      label: 'ナミビア',
    },
    {
      value: 'NR',
      label: 'ナウル',
    },
    {
      value: 'NP',
      label: 'ネパール',
    },
    {
      value: 'NL',
      label: 'オランダ',
    },
    {
      value: 'AN',
      label: 'オランダ領アンティル',
    },
    {
      value: 'NC',
      label: 'ニューカレドニア',
    },
    {
      value: 'NZ',
      label: 'ニュージーランド',
    },
    {
      value: 'NI',
      label: 'ニカラグア',
    },
    {
      value: 'NE',
      label: 'ニジェール',
    },
    {
      value: 'NG',
      label: 'ナイジェリア',
    },
    {
      value: 'NU',
      label: 'ニウエ',
    },
    {
      value: 'NF',
      label: 'ノーフォーク島',
    },
    {
      value: 'MP',
      label: '北マリアナ諸島連邦',
    },
    {
      value: 'NO',
      label: 'ノルウェー',
    },
    {
      value: 'OM',
      label: 'オマーン',
    },
    {
      value: 'PK',
      label: 'パキスタン',
    },
    {
      value: 'PW',
      label: 'パラオ',
    },
    {
      value: 'PS',
      label: 'パレスチナは、占領',
    },
    {
      value: 'PA',
      label: 'パナマ',
    },
    {
      value: 'PG',
      label: 'パプアニューギニアの',
    },
    {
      value: 'PY',
      label: 'パラグアイ',
    },
    {
      value: 'PE',
      label: 'ペルー',
    },
    {
      value: 'PH',
      label: 'フィリピン',
    },
    {
      value: 'PN',
      label: 'ピトケアン',
    },
    {
      value: 'PL',
      label: 'ポーランド',
    },
    {
      value: 'PT',
      label: 'ポルトガル',
    },
    {
      value: 'PR',
      label: 'プエルトリコ',
    },
    {
      value: 'QA',
      label: 'カタール',
    },
    {
      value: 'RE',
      label: 'レユニオン',
    },
    {
      value: 'RO',
      label: 'ルーマニア',
    },
    {
      value: 'RU',
      label: 'ロシア連邦',
    },
    {
      value: 'RW',
      label: 'ルワンダ',
    },
    {
      value: 'SH',
      label: 'セント·ヘレナ島',
    },
    {
      value: 'KN',
      label: 'セントクリストファーネイビス',
    },
    {
      value: 'LC',
      label: 'セントルシア',
    },
    {
      value: 'PM',
      label: 'サンピエール·ミクロン',
    },
    {
      value: 'VC',
      label: 'セントビンセントおよびグレナディーン諸島',
    },
    {
      value: 'WS',
      label: 'サモア',
    },
    {
      value: 'SM',
      label: 'サン·マリノ',
    },
    {
      value: 'ST',
      label: 'サントメプリンシペ',
    },
    {
      value: 'SA',
      label: 'サウジアラビア',
    },
    {
      value: 'SN',
      label: 'セネガル',
    },
    {
      value: 'RS',
      label: 'セルビア',
    },
    {
      value: 'SC',
      label: 'セイシェル',
    },
    {
      value: 'SL',
      label: 'シエラレオネ',
    },
    {
      value: 'SG',
      label: 'シンガポール',
    },
    {
      value: 'SK',
      label: 'スロバキア',
    },
    {
      value: 'SI',
      label: 'スロベニア',
    },
    {
      value: 'SB',
      label: 'ソロモン諸島',
    },
    {
      value: 'SO',
      label: 'ソマリア',
    },
    {
      value: 'ZA',
      label: '南アフリカ',
    },
    {
      value: 'GS',
      label: 'サウスジョージア島',
    },
    {
      value: 'ES',
      label: 'スペイン',
    },
    {
      value: 'LK',
      label: 'スリ·ランカ',
    },
    {
      value: 'SD',
      label: 'スーダン',
    },
    {
      value: 'SR',
      label: 'スリナム',
    },
    {
      value: 'SJ',
      label: 'スバールバル諸島ヤンマイエン島',
    },
    {
      value: 'SZ',
      label: 'スワジランド',
    },
    {
      value: 'SE',
      label: 'スウェーデン',
    },
    {
      value: 'CH',
      label: 'スイス',
    },
    {
      value: 'SY',
      label: 'シリアアラブ共和国',
    },
    {
      value: 'TW',
      label: '中国の台湾省',
    },
    {
      value: 'TJ',
      label: 'タジキスタン',
    },
    {
      value: 'TZ',
      label: 'タンザニア連合共和国',
    },
    {
      value: 'TH',
      label: 'タイ',
    },
    {
      value: 'TL',
      label: '東ティモール',
    },
    {
      value: 'TG',
      label: 'トーゴ',
    },
    {
      value: 'TK',
      label: 'トケラウ',
    },
    {
      value: 'TO',
      label: 'トンガ',
    },
    {
      value: 'TT',
      label: 'トリニダード·トバゴ',
    },
    {
      value: 'TN',
      label: 'チュニジア',
    },
    {
      value: 'TR',
      label: 'トルコ',
    },
    {
      value: 'TM',
      label: 'トルクメニスタン',
    },
    {
      value: 'TC',
      label: 'タークスカイコス諸島',
    },
    {
      value: 'TV',
      label: 'ツバル',
    },
    {
      value: 'UG',
      label: 'ウガンダ',
    },
    {
      value: 'UA',
      label: 'ウクライナ',
    },
    {
      value: 'AE',
      label: 'アラブ首長国連邦',
    },
    {
      value: 'UM',
      label: 'アメリカ合衆国外諸島',
    },
    {
      value: 'UY',
      label: 'ウルグアイ',
    },
    {
      value: 'UZ',
      label: 'ウズベキスタン',
    },
    {
      value: 'VU',
      label: 'バヌアツ',
    },
    {
      value: 'VE',
      label: 'ベネズエラ',
    },
    {
      value: 'VN',
      label: 'ベトナム',
    },
    {
      value: 'VG',
      label: 'イギリス領バージン諸島',
    },
    {
      value: 'VI',
      label: '米領バージン諸島',
    },
    {
      value: 'WF',
      label: 'ワリー·エ·フトゥーナ',
    },
    {
      value: 'EH',
      label: '西サハラ',
    },
    {
      value: 'YE',
      label: 'イエメン',
    },
    {
      value: 'ZM',
      label: 'ザンビア',
    },
    {
      value: 'ZW',
      label: 'ジンバブエ',
    },
  ],
};
