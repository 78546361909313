/*
 * How to use outside component files:
 * https://stackoverflow.com/a/66695008/3000726
 *
 * Why "/index"? Answer here:
 * https://stackoverflow.com/a/67261660/3000726
 */
import { createI18n } from 'vue-i18n/index';
import messages from '@/i18n/locales';


// CONSTANTS
const DEFAULT_LOCALE = 'en';
const INTL_ROUTE_TO_LOCALE_MAP = {
  de: 'de',
  es: 'es',
  fr: 'fr',
  it: 'it',
  jp: 'ja',
};


// INSTANTIATE I18N
const i18n = createI18n({
  locale: DEFAULT_LOCALE,
  fallbackLocale: DEFAULT_LOCALE,
  messages,
  warnHtmlInMessage: 'off', // <- @TODO remove html from messages
});
const { t } = i18n.global;


// HELPERS
const getLocaleFromRoute = (to) => {
  if (!to || !to.params || !to.params.lang) return DEFAULT_LOCALE;

  return INTL_ROUTE_TO_LOCALE_MAP[to.params.lang] || DEFAULT_LOCALE;
};

const setLocaleFromRoute = (to) => {
  i18n.global.locale = getLocaleFromRoute(to);
};


// EXPORTS
export {
  i18n as default,
  t as $t,
  messages,
  DEFAULT_LOCALE,
  INTL_ROUTE_TO_LOCALE_MAP,
  getLocaleFromRoute,
  setLocaleFromRoute,
};
