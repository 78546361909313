import {
  ref,
  reactive,
} from 'vue';
import useUtils from '@/use/utils';

const { loadScript } = useUtils();


// Properties
const isAnalyticsLoaded = ref(false);
const domo = reactive({});
const amplitude = reactive({});


// Methods
async function loadAnalytics(domoEnv) {
  if (
    isAnalyticsLoaded.value
    || process.env.VUE_APP_IS_ANALYTICS_ENABLED === 'false'
  ) return;

  Object.assign(domo, domoEnv);

  try {
    await loadScript({
      src: 'https://anchanchu.www.domo.com/anchanchu.js',
    });

    if (window.anchanchu) {
      window.anchanchu.setDomoId(domo.domoId);
      window.anchanchu.setDomoTier(domo.tier);

      amplitude.userId = window.anchanchu.libraries.amplitude.getSessionId();
      amplitude.deviceId = window.anchanchu.libraries.amplitude.options.deviceId;

      window.anchanchu.trackEvent('page view');
    }
  } catch (error) {
    console.error('FAILED TO LOAD ANALYTICS: ', error);
  }

  isAnalyticsLoaded.value = true;
}

function trackEvent(eventName = null, currentStep, session) {
  if (
    !isAnalyticsLoaded.value
    || process.env.VUE_APP_IS_ANALYTICS_ENABLED === 'false'
  ) return;

  const constantsToTrack = { domoId: domo.domoId, uniqueFormId: domo.uffid };
  const metaToTrack = currentStep?.analyticsMetaFields;
  const fieldsToTrack = currentStep?.fields
    .filter((f) => f.isTrackedByAnalytics)
    .reduce((accum, f) => Object.assign(accum, { [f.name]: session[f.name].value }), {});

  if (eventName) {
    window.anchanchu.trackEvent(eventName, {
      ...constantsToTrack,
      ...metaToTrack,
      ...fieldsToTrack,
    });

    if (window.gtag) {
      // Ref: https://developers.google.com/tag-platform/gtagjs/reference/events#event
      window.gtag('event', eventName, {
        ...constantsToTrack,
        ...metaToTrack,
        ...fieldsToTrack,
      });
    }
  }
}

function fireMarketingPixels() {
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  iframe.src = `${process.env.VUE_APP_API_BASE_URL}/api/pixels`;
  document.body.appendChild(iframe);
}


// Exports
export default function useAnalytics() {
  return {
    loadAnalytics,
    trackEvent,
    fireMarketingPixels,
    amplitude,
  };
}
