import { createRouter, createWebHistory } from 'vue-router';
import routes from '@/router/routes';
import scrollBehavior from '@/router/scroll-behavior';
import { beforeEach, afterEach } from '@/router/guards';

// Provision Router
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
});

// Navigation Guards
router.beforeEach(beforeEach);
router.afterEach(afterEach);

window.$router = router;

// Exports
export default router;
