import {
  ref,
  readonly,
} from 'vue';
import useUtils from '@/use/utils';

const { redirect } = useUtils();

const isTrapStudentModalOpen = ref(false);
const trappedEmail = ref('');
const trappedCallback = ref(null);

export default function useTrapStudent() {
  function trapStudent(email, onNoCallback) {
    isTrapStudentModalOpen.value = true;
    trappedEmail.value = email;
    trappedCallback.value = onNoCallback ?? null;
  }

  function handleNoStudent() {
    isTrapStudentModalOpen.value = false;

    if (trappedCallback.value && typeof trappedCallback.value === 'function') {
      trappedCallback.value();
    }
  }

  function handleYesStudent() {
    if (!trappedEmail.value) return;
    redirect(`https://www.domo.com/start/student?email=${trappedEmail.value}#/confirm-email`);
  }

  return {
    isTrapStudentModalOpen: readonly(isTrapStudentModalOpen),
    trappedEmail: readonly(trappedEmail),
    trapStudent,
    handleNoStudent,
    handleYesStudent,
  };
}
