import {
  ref,
} from 'vue';
import Http from '@/services/http';

// Properties
const isAppstoreEnvLoaded = ref(false);
const appstoreEnv = ref({});


// Load Appstore Environment
const loadAppstoreEnv = async (flowName) => {
  if (isAppstoreEnvLoaded.value || flowName !== 'appstore') return appstoreEnv.value;

  try {
    const { data } = await Http.get('/api/free/appstore');
    appstoreEnv.value = data;
  } catch (error) {
    console.error(error);
  }

  isAppstoreEnvLoaded.value = true;

  return appstoreEnv.value;
};


// Exports
export default function useAppstore() {
  return {
    appstoreEnv,
    loadAppstoreEnv,
  };
}
