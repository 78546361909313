import {
  ref,
  readonly,
} from 'vue';
import { throttle } from 'lodash';

// Properties
const deviceWidth = ref(window.innerWidth);
const deviceHeight = ref(window.innerHeight);
const isWindowResizeListenerMounted = ref(false);


// Methods
const handleWindowResize = throttle(() => {
  deviceWidth.value = window.innerWidth;
  deviceHeight.value = window.innerHeight;
}, 100);


// Exports
export default function useDevice() {
  if (!isWindowResizeListenerMounted.value) {
    window.addEventListener('resize', handleWindowResize);
  }

  return {
    deviceWidth: readonly(deviceWidth),
    deviceHeight: readonly(deviceHeight),
  };
}
