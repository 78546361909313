import useDomo from '@/use/domo';
import useAnalytics from '@/use/analytics';
import useUtm from '@/use/utm';
import useFlow from '@/use/flow';
import useSession from '@/use/session';
import useLang from '@/use/lang';
import useAppstore from '@/use/appstore';
import { setLocaleFromRoute } from '@/i18n';
import { forwardQuery } from '../helpers';


const { loadDomo } = useDomo();
const { loadAnalytics } = useAnalytics();
const { loadUtm } = useUtm();
const { loadFlow, authorizeStep } = useFlow();
const { loadSession } = useSession();
const { loadLang } = useLang();
const { loadAppstoreEnv } = useAppstore();


export default async function beforeEach(to, from) {
  const {
    flowName,
    stepName,
  } = to.params;


  // 1. Load Domo environment
  const domoEnv = await loadDomo();


  // 2. Load Analytics & UTM
  await loadAnalytics(domoEnv);
  await loadUtm();


  // 3. Load Valid Lang & Set Locale
  const {
    langRedirect,
  } = loadLang(to);

  if (langRedirect) {
    return forwardQuery(langRedirect, from);
  }
  setLocaleFromRoute(to);


  // 4. Load Valid Flow
  const {
    flow,
    flowRedirect,
  } = await loadFlow(flowName, to);

  if (flowRedirect) {
    return flowRedirect;
  }


  // 4.1 Maybe load Appstore Env
  const appstoreEnv = await loadAppstoreEnv(flowName);

  // 5. Load & Pre-Pop Session
  const {
    session,
    sessionRedirect,
  } = await loadSession(flow, domoEnv, appstoreEnv, to);

  if (sessionRedirect) {
    return forwardQuery(sessionRedirect, from);
  }


  // 6. Validate Step
  const {
    stepRedirect,
  } = await authorizeStep(to, flowName, stepName, session);

  if (stepRedirect) {
    return forwardQuery(stepRedirect, from);
  }


  // 7. Return Success
  return true;
}
